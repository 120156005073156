define("discourse/plugins/discourse-private-replies/discourse/initializers/extend-for-private-replies", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function registerTopicFooterButtons(api, container, siteSettings) {
    api.registerTopicFooterButton({
      id: "privatereplies",
      icon() {
        const isPrivate = this.get("topic.private_replies");
        return isPrivate ? "far-eye" : "far-eye-slash";
      },
      priority: 250,
      title() {
        const isPrivate = this.get("topic.private_replies");
        return `private_replies.button.${isPrivate ? "public_replies" : "private_replies"}.help`;
      },
      label() {
        const isPrivate = this.get("topic.private_replies");
        return `private_replies.button.${isPrivate ? "public_replies" : "private_replies"}.button`;
      },
      action() {
        if (!this.get("topic.user_id")) {
          return;
        }
        var action;
        if (this.get("topic.private_replies")) {
          action = 'disable';
        } else {
          action = 'enable';
        }
        return (0, _ajax.ajax)('/private_replies/' + action + '.json', {
          type: "PUT",
          data: {
            topic_id: this.get("topic.id")
          }
        }).then(result => {
          this.set("topic.private_replies", result.private_replies_enabled);
        }).catch(_ajaxError.popupAjaxError);
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["private-replies"],
      dependentKeys: ["topic.private_replies"],
      displayed() {
        const topic_owner_id = this.get("topic.user_id");
        var topic = this.get("topic");
        if (siteSettings.private_replies_on_selected_categories_only == false || topic?.category?.custom_fields?.private_replies_enabled) {
          return this.currentUser && (this.currentUser.id == topic_owner_id || this.currentUser.staff);
        }
        return false;
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-privatereplies",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.private_replies_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.28", api => registerTopicFooterButtons(api, container, siteSettings));
    }
  };
});