define("discourse/plugins/discourse-private-replies/discourse/connectors/category-custom-security/private-replies", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PrivateReplies extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "enabled_for_category", [_tracking.tracked], function () {
      return this.args.outletArgs.category.custom_fields.private_replies_enabled;
    }))();
    #enabled_for_category = (() => (dt7948.i(this, "enabled_for_category"), void 0))();
    constructor() {
      super(...arguments);
    }
    enable_for_category(event) {
      this.enabled_for_category = event.target.checked;
    }
    static #_3 = (() => dt7948.n(this.prototype, "enable_for_category", [_object.action]))();
    get category_private_replies_enabled() {
      return this.siteSettings.private_replies_on_selected_categories_only == false || this.enabled_for_category;
    }
  }
  _exports.default = PrivateReplies;
});